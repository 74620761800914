// Import the framework
@import 'node_modules/vanilla-framework';

// Include all of Vanilla Framework
@include vanilla;

.container {
  margin-top: 6rem;
  margin-bottom: 6rem;
}
